import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import './UsageStorage.css'
import NetworkCheckIcon from "@material-ui/icons/NetworkCheck";

const NetworkCheck = () => {

    const getIconColor = (effectiveType) => {
        switch (effectiveType) {
            case '4g':
                return '#2ecc71';
            case '3g':
                return '#f39c12';
            case '2g':
            case 'slow-2g':
                return '#e74c3c';
            default:
                return '#95a5a6';
        }
    };

    return (
        <Grid
            item
            style={{
                display: 'flex',
                marginRight: 10,
                padding: 10,
                alignItems: 'center'
            }}
        >
            <Typography variant="caption" component="div" style={{ color: '#FFFFFF90', marginRight: 10, }}>
                <b>Network</b>
            </Typography>

            <Tooltip
                title={
                    <React.Fragment>
                        <Typography variant="caption" component="div">
                            <b>Tipo conexão: </b> {navigator.connection.effectiveType}
                        </Typography>
                        <Typography variant="caption" component="div">
                            <b>RTT: </b> {navigator.connection.rtt} ms
                        </Typography>
                        <Typography variant="caption" component="div">
                            <b>Downlink: </b> {navigator.connection.downlink}
                        </Typography>
                    </React.Fragment>
                }
                arrow
                placement="bottom">
                <NetworkCheckIcon style={{ color: getIconColor(navigator.connection.effectiveType) }} />
            </Tooltip>
        </Grid>
    )
}

export default NetworkCheck